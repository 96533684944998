import request from "@/common/js/request.js"

const api = {
    login: (params) => {
        return request({
            url: '/api/login',
            method: 'post',
            data: params
        })
    },
    reg: (params) => {
        return request({
            url: '/api/reg',
            method: 'post',
            data: params
        })
    },
    info: (params) => {
        return request({
            url: '/member/info',
            method: 'post',
            data: params
        })
    },
    hospital_create: (params) => {
        return request({
            url: '/member/hospital_create',
            method: 'post',
            data: params
        })
    },
    hospital_lists: (params) => {
        return request({
            url: '/member/hospital_lists',
            method: 'post',
            data: params
        })
    },
    hospital_delete: (params) => {
        return request({
            url: '/member/hospital_delete',
            method: 'post',
            data: params
        })
    },
    doctor_create: (params) => {
        return request({
            url: '/member/doctor_create',
            method: 'post',
            data: params
        })
    },
    doctor_delete: (params) => {
        return request({
            url: '/member/doctor_delete',
            method: 'post',
            data: params
        })
    },
    doctor_lists: (params) => {
        return request({
            url: '/member/doctor_lists',
            method: 'post',
            data: params
        })
    },
    product_lists: (params) => {
        return request({
            url: '/member/product_lists',
            method: 'post',
            data: params
        })
    },
    product_title_lists: (params) => {
        return request({
            url: '/member/product_title_lists',
            method: 'post',
            data: params
        })
    },
    patient_create: (params) => {
        return request({
            url: '/member/patient_create',
            method: 'post',
            data: params
        })
    },
    patient_update: (params) => {
        return request({
            url: '/member/patient_update',
            method: 'post',
            data: params
        })
    },
    renew_password: (params) => {
        return request({
            url: '/api/renew_password',
            method: 'post',
            data: params
        })
    },
    sms: (params) => {
        return request({
            url: '/api/sms',
            method: 'post',
            data: params
        })
    },
    patient_lists: (params) => {
        return request({
            url: '/member/patient_lists',
            method: 'post',
            data: params
        })
    },
    //七牛token
    qiniu_token: (params) => {
        return request({
            url: '/api/qiniu_token',
            method: 'post',
            data: params
        })
    },
    password_renew_password: (params) => {
        return request({
            url: '/member/password_renew_password',
            method: 'post',
            data: params
        })
    },
    patient_delete: (params) => {
        return request({
            url: '/member/patient_delete',
            method: 'post',
            data: params
        })
    },
    hospital_update: (params) => {
        return request({
            url: '/member/hospital_update',
            method: 'post',
            data: params
        })
    },
    doctor_update: (params) => {
        return request({
            url: '/member/doctor_update',
            method: 'post',
            data: params
        })
    },
    //新增单据
    invoice_edit: (params) => {
        return request({
            url: '/api/invoice_edit',
            method: 'post',
            data: params
        })
    },
    //根据订单号获取信息
    byOrderGetInfo: (params) => {
        return request({
            url: '/api/info',
            method: 'post',
            data: params
        })
    },
    //获取发货单模板
    getMould: (params) => {
        return request({
            url: '/api/invoice_edit',
            method: 'post',
            data: {
                'invoice_id': '2'
            }
        })
    },
    //获取确认单模板
    getMould1: (params) => {
        return request({
            url: '/api/invoice_edit',
            method: 'post',
            data: {
                'invoice_id': '1'
            }
        })
    },
    //飞鹅打印
    huipeng: (params) => {
        return request({
            url: '/api/huipeng',
            method: 'post',
            data: params
        })
    },
}

export default api;