<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  data() {
    return {
      isRouter: false,
    };
  },
  created() {
    let val = this.IEVersion();
    if (val == 6 || val == 7 || val == 8 || val == 9) {
      this.$notify({
        title: "提示",
        dangerouslyUseHTMLString: true,
        message:
          '<a href="https://pc.qq.com/detail/1/detail_2661.html" target="_blank">您的IE浏览器版本太低,请升级浏览器。或者点击下载chrome浏览器。</a>',
        type: "warning",
        duration: 0,
      });
    }

    // this.$http.get("./config.json").then((res) => {
    //   localStorage.setItem('__api', res.body.api)
    //   localStorage.setItem('__address', res.body.address)
    //   this.isRouter = true
    // });
  },
  methods: {
    //判断ie11的方法
    IEVersion() {
      var userAgent = navigator.userAgent; //取得浏览器的userAgent字符串
      var isIE =
        userAgent.indexOf("compatible") > -1 && userAgent.indexOf("MSIE") > -1; //判断是否IE<11浏览器
      var isEdge = userAgent.indexOf("Edge") > -1 && !isIE; //判断是否IE的Edge浏览器
      var isIE11 =
        userAgent.indexOf("Trident") > -1 && userAgent.indexOf("rv:11.0") > -1;
      if (isIE) {
        var reIE = new RegExp("MSIE (\\d+\\.\\d+);");
        reIE.test(userAgent);
        var fIEVersion = parseFloat(RegExp["$1"]);
        if (fIEVersion == 7) {
          return 7;
        } else if (fIEVersion == 8) {
          return 8;
        } else if (fIEVersion == 9) {
          return 9;
        } else if (fIEVersion == 10) {
          return 10;
        } else {
          return 6; //IE版本<=7
        }
      } else if (isEdge) {
        return "edge"; //edge
      } else if (isIE11) {
        return 11; //IE11
      } else {
        return -1; //不是ie浏览器
      }
    },
  },
};
</script>
<style lang="scss">
// #nprogress .bar {
//   background: red !important; //自定义颜色
// }
// .van-nav-bar .van-icon {
//   color: #666 !important;
// }
</style>
