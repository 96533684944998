import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Element from 'element-ui'
import "@/common/scss/index.scss";
import 'element-ui/lib/theme-chalk/index.css';
import api from '@/api/api.js'
import VueCookie from 'vue-cookie'
Vue.use(VueCookie);

Vue.use(Element, {
  size: 'medium'  // set element-ui default size
})
Element.Dialog.props.lockScroll.default = false;
Vue.config.productionTip = false;
// fastclick.attach(document.body);
Vue.prototype.$api = api
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");