import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isLogin: false
  },
  getters:{
    isLogin(state){
      return state.isLogin
    }
  },
  mutations: {
    set_isLogin: (state, val) => {
      state.isLogin = val
    },
  },
  actions: {},
  modules: {}
});
