import axios from "axios";
import qs from 'qs'
import Router from '@/router'
import VueCookie from 'vue-cookie'

const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API,
    withCredentials: false,
    timeout: 600000
});

// 请求拦截器
service.interceptors.request.use(
    config => {
        if (VueCookie.get('_token_')) {
            config.headers['authorization'] = 'Bearer ' + VueCookie.get('_token_');
        }
        if (Object.getOwnPropertyNames(config.data ? config.data : {}).length !== 0) {
            config.data = qs.stringify(config.data)
        }
        // get请求映射params参数
        if (config.method === 'get' && config.params) {
            let url = config.url + '?';
            for (const propName of Object.keys(config.params)) {
                const value = config.params[propName];
                var part = encodeURIComponent(propName) + "=";
                if (value && typeof (value) !== "undefined") {
                    if (typeof value === 'object') {
                        for (const key of Object.keys(value)) {
                            let params = propName + '[' + key + ']';
                            var subPart = encodeURIComponent(params) + "=";
                            url += subPart + encodeURIComponent(value[key]) + "&";
                        }
                    } else {
                        url += part + encodeURIComponent(value) + "&";
                    }
                }
            }
            url = url.slice(0, -1);
            config.params = {};
            config.url = url;
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

// 响应拦截器
service.interceptors.response.use(
    response => {
        // console.log('response', response);
        // 如果后台通过header返回token，说明token已经更新，则更新客户端本地token
        // if (response.headers.token) {
        //     store.dispatch('toggleToken', response.headers.token);
        // }
        const res = response.data;
        return res;
    },
    error => {
        if (error == "Error: Request failed with status code 401") {
            Router.replace(`/login`)
        }
        console.log(error)

    }
)

export default service;