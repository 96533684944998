import Vue from "vue";
import VueRouter from "vue-router";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import VueCookie from 'vue-cookie'

NProgress.configure({ showSpinner: false });

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "info"
  },
  {
    path: "/index",
    redirect: "info"
  },
  {
    path: "/print",
    component: () => import("@/views/print.vue"),
    meta: {
      title: '扫码打印',
      requireAuth: false
    },
  },
  {
    path: "/buildMould/:id",
    component: () => import("@/views/buildMould.vue"),
    meta: {
      title: '生成打印模板',
      requireAuth: false
    },
  },
  {
    path: "/buildMouldHtml/:id",
    component: () => import("@/views/buildMouldHtml/index.vue"),
    meta: {
      title: '生成本地打印模板',
      requireAuth: false
    },
  },
  {
    path: "/buildCardMould/:id",
    component: () => import("@/views/card/buildCardMould.vue"),
    meta: {
      title: '生成打卡模板',
      requireAuth: false
    },
  },
  {
    path: "/printFe",
    component: () => import("@/views/printFe.vue"),
    meta: {
      title: '飞鹅打印确认单',
      requireAuth: false
    },
  },
  {
    path: "/login",
    component: () => import("@/views/login.vue"),
    meta: {
      title: '登录',
      requireAuth: false
    },
  },
  {
    path: "/register",
    component: () => import("@/views/register.vue"),
    meta: {
      title: '注册',
      requireAuth: false
    },
  },
  {
    path: "/password",
    component: () => import("@/views/password.vue"),
    meta: {
      title: '找回密码',
      requireAuth: false
    },
  },
  {
    path: "/setPassword",
    component: () => import("@/views/setPassword.vue"),
    meta: {
      title: '修改密码',
      requireAuth: false
    },
  },
  {
    path: "/agreement",
    component: () => import("@/views/agreement.vue"),
    meta: {
      title: '用户协议',
      requireAuth: false
    },
  },
  {
    path: "/index",
    component: () => import("@/views/index.vue"),
    meta: {
      title: '和和义齿',
      requireAuth: true
    },
    children: [
      {
        path: "/info",
        name: "info",
        component: () => import("@/components/info.vue"),
        meta: {
          title: '信息提交列表',
          requireAuth: true
        }
      },
      {
        path: "/list",
        name: "list",
        component: () => import("@/components/list.vue"),
        meta: {
          title: '医院信息',
          requireAuth: true
        }
      },
      // {
      //   path: "/doctor",
      //   name: "doctor",
      //   component: () => import("@/components/doctor.vue"),
      //   meta: {
      //     title: '医生信息',
      //     requireAuth: true
      //   }
      // }
    ]
  },
];

const router = new VueRouter({
  mode: "hash", // 去掉url中的#
  scrollBehavior: () => ({ y: 0 }),
  routes
});

router.beforeEach((to, from, next) => {
  NProgress.start();
  // let token = localStorage.getItem('_token_')
  let token = VueCookie.get('_token_');
  // console.log(token)
  // document.title = to.meta.title
  // next()
  // 判断路由是否需要登录权限
  if (to.meta.requireAuth && !token) {
    next({
      path: `/login`
    });
  } else {
    next();
  }
});

router.afterEach(() => {
  NProgress.done();
});

export default router;
